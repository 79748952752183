<template lang="pug">
div(v-if='Navegador')
  //-Pc
  .d-none.d-md-block
    v-app-bar#navegador(  color='gris'  height='100' app).pr-5.elevation-0.navegador
      .fila-elementos.align-items-center.justify-center
        .columna-imagen
          router-link(:to='{name: "inicio"}')
            v-img(contain :src='Navegador.logo' flat height=55 width='200').ml-1.elevation-0
        .columna-rutas
          .ruta(v-for='{texto, ruta}, i in Navegador.rutas' :key='i')
            button(:seleccionado='ruta=== ruta_actual' @click='dirigir_a(ruta)') 
              span.azul--text.font-weight-bold {{texto}}
        .columna-redes
          .red-social(v-for='{icono, enlace}, i in Navegador.redes_sociales' :key='i' @click='abrir_enlace(enlace)')
            img(:src='icono').pointer.mr-4
  .d-md-none
    v-app-bar( app   v-if='Navegador'  color='negro'   height='100' ).pr-5
      router-link(:to='{name: "inicio"}')
        v-img(contain :src='Navegador.logo'  height=60 width=110).elevation-0
      v-spacer
      v-btn(icon  @click='drawer = !drawer' dark)
        v-icon(color='azul' large) fas fa-bars
    v-navigation-drawer( temporary v-model="drawer" app)
      v-list
        v-list-item
          router-link(:to='{name: "inicio"}')
            v-img( height=85 width=160 :src='Navegador.logo' contain).justify-center
          v-list-item-content
        v-list-item(v-for='{texto, ruta}, i in Navegador.rutas' :key='i' @click='ruta_actual!=ruta ? dirigir_a(ruta) : drawer=false').my-3
          v-list-item-content
            v-list-item-title(v-html='texto').azul--text.font-weight-black
        
</template>
<script>
export default {
  data: ()=>({
    drawer: false,
  }),
  computed: {
    ruta_actual(){
      return this.$route.name
    },
  },
  methods: {
    abrir_enlace(enlace){
      window.open(enlace, '_blank')
    }
  },
}
</script>
<style lang="sass" scoped>
.navegador
  position: sticky
.fila-elementos
  display: flex
  flex-direction: row
  .columna-rutas
    display: flex
    flex-direction: row
    margin-left: 305px
    
    .ruta
      button
        margin-right: 20px
        width: 120px
        height: 50px
        border-radius: 25px!important
        &[seleccionado='true']
          border: 3px solid #e74212!important
        span
          font-family: 'Titillium-Web'
  .columna-redes
    display: flex
    flex-direction: row
    padding-left: 45px
    padding-top: 13px
    .red-social
      img
        width: 23px
        cursor: pointer
</style>