var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Navegador)?_c('div',[_c('div',{staticClass:"d-none d-md-block"},[_c('v-app-bar',{staticClass:"pr-5 elevation-0 navegador",attrs:{"id":"navegador","color":"gris","height":"100","app":""}},[_c('div',{staticClass:"fila-elementos align-items-center justify-center"},[_c('div',{staticClass:"columna-imagen"},[_c('router-link',{attrs:{"to":{name: "inicio"}}},[_c('v-img',{staticClass:"ml-1 elevation-0",attrs:{"contain":"","src":_vm.Navegador.logo,"flat":"","height":"55","width":"200"}})],1)],1),_c('div',{staticClass:"columna-rutas"},_vm._l((_vm.Navegador.rutas),function(ref,i){
var texto = ref.texto;
var ruta = ref.ruta;
return _c('div',{key:i,staticClass:"ruta"},[_c('button',{attrs:{"seleccionado":ruta=== _vm.ruta_actual},on:{"click":function($event){return _vm.dirigir_a(ruta)}}},[_c('span',{staticClass:"azul--text font-weight-bold"},[_vm._v(_vm._s(texto))])])])}),0),_c('div',{staticClass:"columna-redes"},_vm._l((_vm.Navegador.redes_sociales),function(ref,i){
var icono = ref.icono;
var enlace = ref.enlace;
return _c('div',{key:i,staticClass:"red-social",on:{"click":function($event){return _vm.abrir_enlace(enlace)}}},[_c('img',{staticClass:"pointer mr-4",attrs:{"src":icono}})])}),0)])])],1),_c('div',{staticClass:"d-md-none"},[(_vm.Navegador)?_c('v-app-bar',{staticClass:"pr-5",attrs:{"app":"","color":"negro","height":"100"}},[_c('router-link',{attrs:{"to":{name: "inicio"}}},[_c('v-img',{staticClass:"elevation-0",attrs:{"contain":"","src":_vm.Navegador.logo,"height":"60","width":"110"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',{attrs:{"color":"azul","large":""}},[_vm._v("fas fa-bars")])],1)],1):_vm._e(),_c('v-navigation-drawer',{attrs:{"temporary":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',[_c('router-link',{attrs:{"to":{name: "inicio"}}},[_c('v-img',{staticClass:"justify-center",attrs:{"height":"85","width":"160","src":_vm.Navegador.logo,"contain":""}})],1),_c('v-list-item-content')],1),_vm._l((_vm.Navegador.rutas),function(ref,i){
var texto = ref.texto;
var ruta = ref.ruta;
return _c('v-list-item',{key:i,staticClass:"my-3",on:{"click":function($event){_vm.ruta_actual!=ruta ? _vm.dirigir_a(ruta) : _vm.drawer=false}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"azul--text font-weight-black",domProps:{"innerHTML":_vm._s(texto)}})],1)],1)})],2)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }